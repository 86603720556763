import React from 'react'
import Link from 'next/link'

function Read({content,link,also}) {
  return (
    <main className='container  text-center bg-light p-2 rounded-custom mt-1 mt-lg-0'
        style={{fontSize: "1.2rem",paddingTop: "8%"}}
    >
        <p style={{marginTop: 18}}>{also ? 'Also Read:\u00A0\u00A0' : 'Read more:\u00A0\u00A0'}
        <Link href={link} >
        <a >{content}</a>
        </Link>
        </p>
    </main>
  )
}

export default Read