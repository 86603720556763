import { useEffect } from "react";
import Head from "next/head";
import favicon from "/public/favicon.svg";
const Layout = ({
  children,
  title,
  desc,
  hasClass,
  classOpt,
  images,
  link,
  script,
  ScriptTwo,
  keyWords,
}) => {
  useEffect(() => {
    const loadGTM = () => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-M6L42P6X");
    };
    const timeoutId = setTimeout(loadGTM, 2000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div
      className={`main-wrapper ${
        hasClass ? "overflow-hidden" : ""
      } ${classOpt}`}
    >
      <Head>
        <title>
          {title
            ? ` ${title}`
            : "ZervX - Powerful Mobility Tool for Sucessful Business"}
        </title>
        {desc && <meta name="description" content={desc} />}
        {/* <link rel="icon" as="image" href={favicon.src} type="image/svg+xml"  /> */}
        <link rel="icon" href={`${favicon.src}?v=2`} type="image/svg+xml" />
        <link rel="icon" href="/public/favicon.ico?v=2" sizes="any" />
        <link rel="icon" href="/public/favicon.svg?v=2" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/public/apple-touch-icon.png?v=2" sizes="180x180" type="image/png" />
        {link && <link rel="canonical" href={link} />}
        <meta name="keywords" content={keyWords} />
        {/* share  URL the image has display */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={link} />
        <meta
          property="og:image"
          content={`https://www.zervx.com${images.src}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:url" content={link} />
        <meta
          name="twitter:image"
          content={`https://www.zervx.com${images.src}`}
        />
        {/* <meta name="twitter:image:type" content="image/png" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" /> */}
        {ScriptTwo && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(ScriptTwo),
            }}
          />
        )}
        {script && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(script),
            }}
          />
        )}
      </Head>
      {children}
    </div>
  );
};
export default Layout;
